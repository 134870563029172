<template>
  <div id="navcard">
    <v-card height="auto" width="256" id="drawer">
      <v-navigation-drawer permanent>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title id="drawer-title">
              {{ $t("message.ProjectsTitle") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav id="text-style">
          <v-list-item
            id="nav-btn"
            v-for="item in items"
            :key="item.title"
            link
            @click="$router.push({ path: item.route })"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h7">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <div id="prom-text">
      {{ $t("message.PromText") }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "message.NavbarTT", route: "/projects/TampereenTilapalvelut" },
        // { title: "message.NavbarCocacola", route: "/projects/cocacola" },
        // {
        //   title: "message.NavbarElectrum",
        //   route: "/projects/electrum",
        // },
        // { title: "message.NavbarSVGK", route: "/projects/svgk" },
        // { title: "message.NavbarTrasko", route: "/projects/trasko" },
        // {
        //   title: "message.NavbarResourstrans",
        //   route: "/projects/resourstrans",
        // },
      ],
    };
  },
};
</script>

<style>
#drawer {
  display: flex;
}
#drawer-title {
  letter-spacing: normal;
  font-size: 32px;
  font-family: Ubuntu,Arial,sans-serif;
}
#text-style {
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#nav-btn {
  border: 2px solid;
}
#navcard {
  display: block;
}
#prom-text {
  color: #666666;
  font-style: italic;
  padding-top: 40px;
  margin-left: 3%;
  margin-right: 3%;
  width: 250px;
  font-size: 13px;
  font-family: Ubuntu,Arial,sans-serif;
}
#subpage-content {
  height: 100%;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 160px;
  display: flex;
}
#rcol {
  margin-left: 5%;
}
#rcol-incard {
  padding: 30px;
}
#about-text {
  padding-top: 20px;
}
#font-st{
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
/* Для интеграции */
#int-about-text {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#pad {
  padding-top: 20px;
}
#buy-card {
  display: flex;
  background-color: #e2e2e2;
  margin-top: 50px;
  margin-bottom: 50px;
}
#buy-btn {
  margin: 15px;
  display: flex;
  align-self: center;
  width: 25%;
  font: 14px/20px Ubuntu, Arial, sans-serif;
  color: rgb(247, 247, 247);
  background-color: #00569c;
  letter-spacing: 0.01em;
  
}
#history-card-text {
  padding: 15px;
  background-color: #eaf0f2;
  width: 75%;
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#naming-pad {
  font: 24px "Open Sans", sans-serif;
  font-weight: bold;
  display: inline;
  border-bottom: 2px solid #00569c;
}
@media (max-width: 1220px) {
  #subpage-content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 220px;
  }
}
@media (max-width: 970px) {
  #navcard{
    display: none;
  }
  #buy-card{
    flex-direction: column;
  }
  #buy-btn {
    width: 50%;
  }
  #history-card-text {
  width: 100%;
}
}
@media (max-width: 450px) {
  #subpage-content {
    padding-left: 0%;
    padding-right: 0%;
  }
}
</style>